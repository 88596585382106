import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './history.scss';
import Logo from '../assets/photo_2_2024-08-17_15-14-53-removebg-preview.png';
import ProfileImage from '../assets/images.jpg';
import { useNavigate } from 'react-router-dom';
import defaultAvatar from '../assets/images.jpg';

const History = () => {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const [fullName, setFullName] = useState(null);
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [email, setEmail] = useState(null);
  
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get('https://thepartnersapi.jetsonweb.com/api/partner/order-history', {
          withCredentials: true, // Ensure cookies are sent with request
        });
        console.log('Order History:', response.data); // Log response
        setOrders(response.data); // Set the fetched data to state
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to fetch order history'); // Set error message if request fails
      }
    };

    fetchUserProfile(); // Call the function on component mount
  }, []);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get('https://thepartnersapi.jetsonweb.com/api/partners/profile', { 
          withCredentials: true 
        });

        const userData = response.data;
        setUserInfo(userData);

        if (userData) {
          setFullName(userData.full_name);
          setEmail(userData.email);
          // Store the email locally for further use
          localStorage.setItem('userEmail', userData.email);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Could not retrieve user profile. Please try again.');
      }
    };

    fetchUserProfile();
  }, []);
  if (error) {
    return <div>{error}</div>;
  }
  const handleNavigate = () => {
    navigate('/History');
  };

  const handleHome = () => {
    navigate('/Home');
  };



  return (
    <div>
<header className="header">
        <div className="logo-container">
          <img className="logo" src={Logo} alt="Logo" />
        </div>
        <nav className="nav-bar">
          <a onClick={handleHome} className="nav-link">Home</a>
          <a onClick={handleNavigate} className="nav-link">History</a>
        </nav>
          <div className="profile-image">
          {userInfo && userInfo.profile_image ? (
            <img 
              src={`https://thepartnersapi.jetsonweb.com${userInfo.profile_image}`} 
              alt="Profile" 
              className="profile-img"
            />
          ) : (
            <img 
              src={defaultAvatar} 
              alt="Default Profile" 
              className="profile-img"
            />
          )}
        </div>
        <div className="profile-info">
          <h3>{userInfo ? userInfo.full_name : 'Loading...'}</h3>
          </div>
      </header>

      <section className="hero">
        <div className="hero-content">
          <h1>Your Product Awaits</h1>
          <p>Experience excellence with our top-of-the-line roasted chickens. Easy ordering.</p>
        </div>
      </section>
      <section className="order-history">
      <h2>Order History</h2>
      {orders.length === 0 ? (
        <p>No orders found.</p>
      ) : (
        <ul>
          {orders.map(order => (
            <li key={order.id}>
              <p>Order ID: {order.id}</p>
              <p>Quantity: {order.quantity}</p>
              <p>Status: {order.status}</p>
              <p>Details: {order.order_details}</p>
              <p>Date: {new Date(order.created_at).toLocaleDateString()}</p>
            </li>
          ))}
        </ul>
      )}
      </section>
    </div>
    
  );
};

export default History;
