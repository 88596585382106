import React, { useState } from "react";
import axios from "axios";
import "./forget.scss"; // Import CSS for styling
import ResetPassword from "../resetpass/Resetpassword";
import { useNavigate } from "react-router-dom";

const Forget = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await axios.post("https://thepartnersapi.jetsonweb.com/api/forgot-password", { email });
        // Check if the response exists and has data
        if (response && response.data) {
          setMessage(response.data.message); // Show success message
        } else {
          setMessage("Unexpected response format.");
        }
      } catch (err) {
        // Check for error response
        if (err.response) {
          setMessage(err.response.data.error); // Show error message from response
        } else if (err.request) {
          setMessage("No response received from server. Please try again later.");
        } else {
          setMessage("Error: " + err.message); // Generic error message
        }
      }
    };
    
    
  
    return (
      <div className="forgot-password-container">
        <div className="form-card">
          <h2>Forgot Password</h2>
          <p>Enter your email address to receive a password reset link.</p>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              className="input-field"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            
            <button type="submit" className="submit-button">Submit</button>
          </form>
          {message && <p className="message">{message}</p>}
        </div>
       
      </div>
    );
  };


export default Forget