import React, { useEffect, useState } from 'react';
import './home.scss';
import Logo from '../assets/photo_2_2024-08-17_15-14-53-removebg-preview.png';
import Lottie from 'react-lottie';
import animationData from '../image/HpPJRqAtjC.json';
import animationretrun from '../image/VGjgwMlzJL.json';
import axios from 'axios'; // Using axios to fetch data
import { useNavigate } from 'react-router-dom'; 
import defaultAvatar from '../assets/images.jpg';

const HomePage = () => {
  const [activeTab, setActiveTab] = useState('order');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [userInfo, setUserInfo] = useState(null); // To store user information
  const [error, setError] = useState(null); // To store any errors
  const [fullName, setFullName] = useState(''); // Full name
  const [companyName, setCompanyName] = useState(''); // Company name
  const [email, setEmail] = useState(''); // Email
  const [phoneNumber, setPhoneNumber] = useState(''); // Phone number
  const [quantity, setQuantity] = useState(0); // Quantity
  const [location, setLocation] = useState(''); // New state for location
  const [orderDetails, setOrderDetails] = useState(''); // Order details

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const defaultOptionsreturn = {
    loop: true,
    autoplay: true,
    animationData: animationretrun,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    axios.get('https://thepartnersapi.jetsonweb.com/api/partners/profile', { withCredentials: true })
      .then(response => {
        setUserInfo(response.data);
        if (response.data) {
          setFullName(response.data.full_name); // Set the full name to the input field
          setEmail(response.data.email);       // Set the email to the input field
        }
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
        setError('Error fetching user data.');
      });
  }, []);

  
  const switchTab = (tabName) => {
    setActiveTab(tabName);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
  
    // Check if all required fields are filled, including the new location field
    if (!fullName || !companyName || !email || !phoneNumber || !quantity || !orderDetails || !location) {
      alert("All fields are required!");
      return;
    }
  
    // Prepare the order data to include location
    const orderData = {
      full_name: fullName,
      company_name: companyName,
      email: email,
      phone_number: phoneNumber,
      quantity: parseInt(quantity, 10), // Ensure it's a number
      order_details: orderDetails,
      location: location, // Added location
    };
  
    console.log('Order Data:', orderData); // Log the order data to inspect
  
    try {
      const response = await axios.post('https://thepartnersapi.jetsonweb.com/api/orders', orderData);
      console.log('Success:', response.data);
      setModalMessage('Order submitted successfully!');
      setIsModalVisible(true);
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      setModalMessage('Order submission failed!');
      setIsModalVisible(true);
    }
  };
  
  const handleReturnSubmit = async (event) => {
    event.preventDefault();
  
    // Validation check for return form
    if (!fullName || !companyName || !email || !phoneNumber || !orderDetails) {
      alert("All fields are required for the return!");
      return;
    }
  
    // Prepare return data to send to the API
    const returnData = {
      name: fullName, // The full name
      company_name: companyName, // Company name
      email: email, // Email address
      phone_number: phoneNumber, // Phone number
      comment: orderDetails, // Comment/Reason for return
    };
  
    console.log('Return Data:', returnData); // Log the return data
  
    try {
      // Send POST request to the return API endpoint
      const response = await axios.post('https://thepartnersapi.jetsonweb.com/api/returns', returnData);
      console.log('Success:', response.data);
  
      // Set success message and show modal
      setModalMessage('Return order submitted successfully!');
      setIsModalVisible(true);
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
  
      // Set failure message and show modal
      setModalMessage('Return submission failed!');
      setIsModalVisible(true);
    }
  };
  
  

  const closeModal = () => {
    setIsModalVisible(false);
  };
  const navigate = useNavigate(); 
  const handleNavigate = () => {
    navigate('/History');  // Navigate to the home page when the button is clicked
  };
  const handlehome = () => {
    navigate('/Home');  // Navigate to the home page when the button is clicked
  };
  return (
    <div className="home-container">
      <header className="header">
        <div className="logo-container">
          <img className="logo" src={Logo} alt="Logo" />
        </div>
        <nav className="nav-bar">
          <a onClick={handlehome} className="nav-link">Home</a>
          <a onClick={handleNavigate} className="nav-link">History</a>
        </nav>
        <div className="profile-image">
              {userInfo && userInfo.profile_image ? (
                <img 
                  src={`https://thepartnersapi.jetsonweb.com${userInfo.profile_image}`} 
                  alt="Profile" 
                  className="profile-img"
                />
              ) : (
                <img 
                  src={defaultAvatar} 
                  alt="Default Profile" 
                  className="profile-img"
                />
              )}
            </div>

          <div className="profile-info">
          <h3>{userInfo ? userInfo.full_name : 'Loading...'}</h3>
          </div>

      </header>

      <section className="hero">
        <div className="hero-content">
          <h1>Your Product Awaits</h1>
          <p>Experience excellence with our top-of-the-line roasted chickens. Easy ordering.</p>
        </div>
      </section>

      {/* Tab Bar */}
      <div className="tab-bar">
        <button
          className={`tab ${activeTab === 'order' ? 'active' : ''}`}
          onClick={() => switchTab('order')}
        >
          Order
        </button>
        <button
          className={`tab ${activeTab === 'return' ? 'active' : ''}`}
          onClick={() => switchTab('return')}
        >
          Return
        </button>
      </div>

      <div className="form-container">
        {/* Order Form */}
        <section
          id="order-form"
          className={`form-section ${activeTab === 'order' ? 'active-slide' : ''}`}
        >
          <div className="form-content">
            <h2>Order Now</h2>
            <form onSubmit={handleFormSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Full Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">  
              <input
                  type="email"
                  placeholder="Email"
                  value={email} // Controlled by the email state
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
              <input
                  type="text"
                  placeholder="Location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}  // New input for location
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="number"
                  placeholder="Quantity"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <textarea
                  placeholder="Order Details"
                  rows="4"
                  value={orderDetails}
                  onChange={(e) => setOrderDetails(e.target.value)}
                  required
                ></textarea>
              </div>
              <button type="submit">Place Order</button>
            </form>
          </div>
          <div className="lottie-container">
            <Lottie options={defaultOptions} height={400} width={400} />
          </div>
        </section>

        {/* Return Form */}
        <section
          id="return"
          className={`form-section ${activeTab === 'return' ? 'active-slide' : ''}`}
        >
          <div className="form-content">
            <h2>Return Now</h2>
            <form onSubmit={handleReturnSubmit}>
      <div className="form-group">
        <input
          type="text"
          placeholder="Full Name"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          placeholder="Company Name"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
      <input
    type="email"
    placeholder="Email"
    value={email} // Controlled by the email state
    onChange={(e) => setEmail(e.target.value)}
    required
  />
      </div>
      <div className="form-group">
        <input
          type="tel"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <textarea
          placeholder="Comment"
          rows="4"
          value={orderDetails}
          onChange={(e) => setOrderDetails(e.target.value)}
          required
        ></textarea>
      </div>
      <button type="submit">Submit Return</button>
    </form>
          </div>
          <div className="lottie-container">
            <Lottie options={defaultOptionsreturn} height={400} width={400} />
          </div>
        </section>
      </div>
      <footer id="contact" className="footer">
        <p>For inquiries, contact us at <a href="mailto:info@jetsondoro.com">info@jetsondoro.com</a></p>
      </footer>
      {/* Modal for submission feedback */}
      {isModalVisible && (
        <div className="modal">
          <div className="modal-content">
            <h2>{modalMessage}</h2>
            <button className="close-button" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
