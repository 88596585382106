import React, { useState } from 'react';
import './signup.scss';
import Logo from '../assets/photo_2_2024-08-17_15-14-53.jpg'; // Update with the actual path to your logo
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons from react-icons
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios for making HTTP requests


const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [profileImage, setProfileImage] = useState(null); // State for profile image
  const [fullName, setFullName] = useState('');
  const [username, setUsername] = useState(''); // State for username
  const [companyName, setCompanyName] = useState(''); // State for company name
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // Added confirm password state
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // Function to handle image selection
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setProfileImage(event.target.files[0]); // Update profile image state
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    // Ensure all fields are provided
    if (!fullName || !username || !companyName || !email || !password || !confirmPassword || !profileImage) {
      setErrorMessage("All fields are required!");
      return;
    }

    // Ensure passwords match
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match!");
      return;
    }

    const formData = new FormData();
    formData.append('full_name', fullName);
    formData.append('username', username);
    formData.append('company_name', companyName);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('confirmPassword', confirmPassword);
    formData.append('profile_image', profileImage); // Make sure this is the correct key

    // Debug log: Check what data is being sent
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    // Submit the form after logging
    try {
      const response = await axios.post('https://thepartnersapi.jetsonweb.com/api/signup', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Ensure content-type is set to multipart/form-data
        },
      });
      console.log('Success:', response.data);
      setSuccessMessage('Partner added successfully. Pending admin approval.');
      
      // Clear form fields after successful submission
      setFullName('');
      setUsername('');
      setCompanyName('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setProfileImage(null); // Clear profile image

      setTimeout(() => {
        navigate("/Pending"); // Redirect to pending page after success
      }, 2000); // Delay to show success message before redirecting
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage("Signup failed! Please try again.");
    }
  };

  return (
    <div className="signup-container">
      <div className="top">
        <div className="wrapper">
          <img className="logo" src={Logo} alt="Logo" />
        </div>
      </div>
      <div className="form-containerS">
        <h2 className="title">Create an Account</h2>
        <form onSubmit={handleSubmit}>
          <div className="image-picker-container">
            <div className="image-preview">
              {profileImage ? (
                <img
                  src={URL.createObjectURL(profileImage)}
                  alt="Profile"
                  className="profileImagePreview"
                />
              ) : (
                <div className="image-placeholder">
                  No Image
                </div>
              )}
            </div>
            <button
              type="button"
              className="chooseImageButton"
              onClick={() => document.getElementById('profileImage').click()}
            >
              Choose Image
            </button>
            <input
              id="profileImage"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: 'none' }}
            />
          </div>
          <input 
            type="text" 
            className="input-field" 
            placeholder="Full Name" 
            value={fullName} 
            onChange={(e) => setFullName(e.target.value)} 
          />
          <input 
            type="text" 
            className="input-field" 
            placeholder="Username" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
          />
          <input 
            type="text" 
            className="input-field" 
            placeholder="Company Name" 
            value={companyName} 
            onChange={(e) => setCompanyName(e.target.value)} 
          />
          <input 
            type="email" 
            className="input-field" 
            placeholder="Email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
          />
          
          <div className="password-container">
            <input
              type={showPassword ? 'text' : 'password'}
              className="input-field"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button type="button" className="toggle-password" onClick={togglePasswordVisibility}>
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </button>
          </div>
          <div className="password-container">
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              className="input-field"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button type="button" className="toggle-password" onClick={toggleConfirmPasswordVisibility}>
              {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
            </button>
          </div>
          <button type="submit" className="signup-button">Sign Up</button>
        </form>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
        <p className="footer-text">Already have an account? <a onClick={() => navigate("/Login")} className="link">Log in</a></p>
      </div>
    </div>
  );
};

export default Signup;
