import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "./resetpassword.scss";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleReset = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put("https://thepartnersapi.jetsonweb.com/api/partners/update-password", { email, password: newPassword });
      setMessage(response.data.message);
      setTimeout(() => navigate("/login"), 3000); // Redirect after success
    } catch (err) {
      setMessage(err.response?.data?.error || "An error occurred");
    }
  };

  return (
    <div className="reset-password-container">
      <div className="reset-password-card">
        <h2 className="title">Reset Password</h2>
        <p className="subtitle">
          Enter your email and a new password below to reset your account password.
        </p>
        <form onSubmit={handleReset} className="reset-password-form">
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="input"
          />
          <input
            type="password"
            placeholder="Enter new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            className="input"
          />
          <button type="submit" className="btn">
            Reset Password
          </button>
        </form>
        {message && <p className={`message ${message.includes("success") ? "success" : "error"}`}>{message}</p>}
      </div>
    </div>
  );
};

export default ResetPassword;
